import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { styled } from "twin.macro";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import PageHero from "../components/PageSections/PageHero";
import TwoPanel from "../components/PageSections/TwoPanels";
import AboutText from "../components/PageSections/AboutText";
// import Carousel from "../components/PageSections/Carousel";
import ContactUs from "../components/PageSections/ContactUs";
import GetInvolved from "../components/PageSections/GetInvolved";
import AboutPress from "../components/PageSections/AboutPress";
import CoreTeam from "../components/PageSections/CoreTeam";
import ExpandListItem from "../components/PageSections/ExpandListItem";

const List = styled.ul`
  padding-top: 6px;

  @media (min-width: 768px) {
    padding-top: 25px;
  }

  @media (min-width: 1024px) {
    padding-top: 0;
  }
`;

const AboutComponent = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "about" } }) {
        nodes {
          title
          template
          slug
          id
          content
          comment_status
          menu_order
          path
          acf {
            hero {
              art {
                source_url
              }
              artist {
                age
                credit
              }
              title
              copy
              cta_type
              cta_text
              cta_link
            }
            two_column {
              left_column
              right_column {
                copy
                title
              }
            }
            content
            contact
            press {
              content
              press {
                title
                source
                link
              }
            }
            testimonials {
              image {
                source_url
              }
              testimonial
              attribution
            }
          }
          yoast_meta {
            yoast_wpseo_social_defaults {
              og_frontpage_image {
                source_url
              }
            }
            yoast_wpseo_metadesc
            yoast_wpseo_facebook_title
            yoast_wpseo_facebook_image {
              source_url
            }
            yoast_wpseo_facebook_description
            yoast_wpseo_twitter_title
            yoast_wpseo_twitter_image {
              source_url
            }
            yoast_wpseo_twitter_description
          }
        }
      }
    }
  `);

  const aboutData = data.allWordpressPage.nodes[0];
  const { yoast_meta, title } = data.allWordpressPage.nodes[0];

  const {
    yoast_wpseo_metadesc: seo_desc,
    yoast_wpseo_facebook_title: seo_fb_title,
    yoast_wpseo_facebook_description: seo_fb_desc,
    yoast_wpseo_twitter_title: seo_tw_title,
    yoast_wpseo_twitter_description: seo_tw_desc
  } = yoast_meta;

  const seo_image =
    yoast_meta.yoast_wpseo_social_defaults?.og_frontpage_image?.source_url ||
    "";
  const seo_fb_image =
    yoast_meta.yoast_wpseo_facebook_image?.source_url || seo_image;
  const seo_tw_image =
    yoast_meta.yoast_wpseo_twitter_image?.source_url || seo_image;

  const { hero, content, contact, press, two_column } = aboutData?.acf;

  return (
    <Layout>
      <SEO
        title={title}
        description={seo_desc}
        image={seo_fb_image}
        metaTitleFacebook={seo_fb_title}
        metaImageFacebook={seo_fb_image}
        metaDescFacebook={seo_fb_desc}
        metaTitleTwitter={seo_tw_title}
        metaImageTwitter={seo_tw_image}
        metaDescTwitter={seo_tw_desc}
      />
      <PageHero {...hero} />
      <AboutText text={content} />
      <TwoPanel
        left={two_column?.left_column}
        list={true}
        right={
          <List>
            {two_column.right_column.map((item, index) => (
              <ExpandListItem copy={item.copy} title={item.title} key={index} />
            ))}
          </List>
        }
      />
      <CoreTeam />
      <AboutPress {...press} />
      <ContactUs content={contact} />
      <GetInvolved />
    </Layout>
  );
};

export default AboutComponent;
