import React, { useEffect } from "react";
import tw, { styled } from "twin.macro";
import { ReactComponent as CloseIcon } from "../../images/ICON_GREY_CLOSE.svg";
import ProfileImgFallback from "../../images/profilefallback.png";

const BioComponent = styled.article`
  transition: opacity 0.5s ease;
  opacity: 0;
  position: fixed;

  &.open-false {
    pointer-events: none;
    height: 0;
  }

  &.open-true {
    z-index: 10;
    opacity: 1;
    background-color: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 4rem 2rem 2rem;
  }

  @media (min-width: 768px) {
    &.open-true {
      padding: 6rem 8rem 4rem;
    }
  }

  @media (min-width: 1224px) {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.open-true {
      padding: 180px 188px;
    }
  }
`;

const InnerContent = styled.div`
  ${tw`
    w-full
  `};

  position: relative;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    max-width: 1064px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    overflow-y: scroll;
    height: 100%;
  }
`;

const Close = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  svg {
    fill: var(--color-grey);
    height: 15px;
    width: 15px;
  }

  @media (min-width: 768px) {
    right: 64px;
    top: 30px;
    svg {
      fill: var(--color-grey);
      height: 25px;
      width: 25px;
    }
  }

  @media (min-width: 1024px) {
    top: 60px;
    right: 80px;

    svg {
      height: 30px;
      width: 30px;
    }
  }
`;

const BioDetails = styled.aside`
  ${tw`
    relative w-full lg:w-1/3
  `};

  // @media (min-width: 1024px) {
  //   max-width: calc(50% - 124px);
  // }
`;

const BioImage = styled.img`
  max-width: 100%;
  width: 100%;

  margin-bottom: 20px;

  max-width: 420px;
`;

const Name = styled.p`
  font-weight: 600;

  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.17px;
  margin-bottom: 3px;

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.71px;
    margin-bottom: 5px;
  }
`;

const Title = styled.p`
  font-weight: 600;

  ${tw`
    text-lg
  `};
`;

const Description = styled.aside`
  // position: relative;

  ${tw`
    relative w-full lg:w-3/5 lg:ml-auto
  `};

  p {
    ${tw`
      text-base relative
    `};

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }

  // @media (min-width: 1024px) {
  //   width: 50%;
  //   padding-bottom: 0;
  //   position: relative;
  //   right: 0;
  //   top: 0;
  //   min-height: 600px;
  // }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    display: block;
    height: 2rem;
    background: linear-gradient(hsla(0, 0%, 100%, 0.1), #fff 50%);
  }
`;

const BioCopy = styled.div`
  position: relative;
  height: 640px;
  overflow: scroll;

  @media (max-width: 768px) {
    overflow: auto;
    height: inherit;
  }
`;

const Bio = ({ isModalOpen, currentMember, onClick }) => {
  const handleClickOut = e => {
    switch (e.target.nodeName) {
      case "IMG":
      case "P":
      case "ASIDE":
        return;
        break;
      case "ARTICLE":
        onClick();
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    const wrapper = document.getElementById("gatsby-focus-wrapper");
    isModalOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  return (
    <BioComponent className={`open-${isModalOpen}`} onClick={handleClickOut}>
      <Close onClick={onClick}>
        <CloseIcon />
      </Close>
      <InnerContent>
        <BioDetails>
          <BioImage src={currentMember?.image || ProfileImgFallback} />
          <Name>{currentMember?.title}</Name>
          <Title className="mb-0">{currentMember?.acf?.title}</Title>
          {currentMember?.acf?.email ? (
            <p>
              <a className="text-lg" href={`mailto:${currentMember.acf.email}`}>
                {currentMember?.acf?.email}
              </a>
            </p>
          ) : (
            ""
          )}
        </BioDetails>
        <Description>
          <BioCopy
            dangerouslySetInnerHTML={{ __html: currentMember?.content }}
          ></BioCopy>
        </Description>
      </InnerContent>
    </BioComponent>
  );
};

export default Bio;
