import React from "react";
import { styled } from "twin.macro";

const InnerContainer = styled.section`
  padding: 60px 48px;

  h1,
  h2,
  h3 {
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: -0.16px;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
    letter-spacing: -0.27px;
  }

  @media (min-width: 768px) {
    padding: 90px 118px;

    h1,
    h2,
    h3 {
      font-size: 28px;
      line-height: 34px;
      letter-spacing: -0.71px;
      margin-bottom: 30px;
    }
    p {
      font-size: 19px;
      line-height: 29px;
      letter-spacing: -0.36px;
      margin-bottom: 25px;
    }
  }
  @media (min-width: 1024px) {
    padding: 110px 0px;
    width: 58.33333333%;
    max-width: 900px;
    margin: 0 auto;
    h1,
    h2,
    h3 {
      font-size: 38px;
      line-height: 47px;
      letter-spacing: -0.71px;
    }
    p {
      font-size: 22px;
      line-height: 34px;
      letter-spacing: -0.41px;
      margin-bottom: 30px;
    }
  }
`;

const AboutText = ({ text }) => {
  return text ? <div className="section-border"><InnerContainer dangerouslySetInnerHTML={{ __html: text }} /></div> : null;
};

export default AboutText;
