import React, { useState } from "react";
import { styled } from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";
import Bio from "./Bio";
import ProfileFallback from "../../images/profilefallback.png";
import ArrowLeft from "../../images/ICON_GREY_ARROWLEFT.svg";
import ArrowRight from "../../images/ICON_GREY_ARROWRIGHT.svg";
import Button from "../PageComponents/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CoreTeamWrapper = styled.section`
  text-align: center;
  padding: 60px 48px;

  @media (min-width: 768px) {
    padding: 88px 66px;
  }

  @media (min-width: 1024px) {
    padding: 118px 80px 60px;
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const SectionTitle = styled.h3`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.11px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.15px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.16px;
  }
`;

const BigTitle = styled.h4`
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1.83px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    font-size: 55px;
    line-height: 55px;
    letter-spacing: -2.51px;
    margin-bottom: 75px;
  }

  @media (min-width: 1024px) {
    font-size: 90px;
    line-height: 91px;
    letter-spacing: -4.11px;
    margin-bottom: 90px;
  }
`;

const MobileSlider = styled.aside`
  .slick-slide {
    pointer-events: none;

    &.slick-active,
    &.slick-current {
      pointer-events: all;
    }
  }
  .slick-prev,
  .slick-next {
    top: 40%;
  }
  .slick-prev {
    &::before {
      background-size: 12px;
      background-repeat: no-repeat;
      background-image: url(${ArrowLeft});
      background-position: center;
      color: transparent;
      height: 11px;
      width: 11px;
    }
  }
  .slick-next {
    &::before {
      background-size: 12px;
      background-image: url(${ArrowRight});
      background-position: center;
      background-repeat: no-repeat;
      color: transparent;
      height: 11px;
      width: 11px;
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const TeamGrid = styled.ul`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

const ProfileCard = styled.li`
  @media (min-width: 768px) {
    width: calc(50% - 19px);
    text-align: left;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    width: calc(33.3% - 33.5px);
    margin-bottom: 60px;
  }
`;

const ProfileImage = styled.img`
  max-width: 100%;
  width: 100%;
  cursor: pointer;

  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 10px;
  }
`;

const ProfileName = styled.p`
  font-weight: 600;

  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.56px;
    margin-bottom: 7px;
  }

  @media (min-width: 1024px) {
    font-size: 27px;
    line-height: 32px;
    letter-spacing: -0.68px;
    margin-bottom: 5px;
  }
`;

const ProfileTitle = styled.p`
  font-weight: 600;

  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.11px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.15px;
    margin-bottom: 8px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.16px;
    margin-bottom: 10px;
  }
`;

const CoreTeam = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressWpTeam(limit: 30) {
        nodes {
          wordpress_id
          title
          acf {
            title
            email
          }
          content
          _links {
            wp_featuredmedia {
              embeddable
              href
            }
          }
        }
      }
      allWordpressWpMedia {
        nodes {
          id
          title
          wordpress_id
          link
          localFile {
            url
          }
        }
      }
    }
  `);
  console.log(data);

  const [teamMembers] = useState(
    data?.allWordpressWpTeam?.nodes.map(item => {
      const hasMedia = item._links.wp_featuredmedia?.length;

      if (hasMedia) {
        const ID = item._links.wp_featuredmedia[0].href.split("/")[
          item._links.wp_featuredmedia[0].href.split("/").length - 1
        ];
        console.log({ ID });
        const image = data?.allWordpressWpMedia?.nodes.find(
          media => media.wordpress_id === parseInt(ID)
        );
        console.log({ image });
        return {
          image: image?.localFile?.url,
          ...item
        };
      } else
        return {
          image: null,
          ...item
        };
    })
  );

  const [isModalOpen, setModalOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    fade: true,
    slidesToScroll: 1
  };

  const handleClick = id => {
    const user = teamMembers.find(user => user.wordpress_id === id);
    setCurrentMember(user);
    setModalOpen(true);
  };

  return (
    <>
      <div className="section-border">
        <CoreTeamWrapper>
          <SectionTitle>Who We Are</SectionTitle>
          <BigTitle>Our Core Team</BigTitle>
          <MobileSlider>
            <Slider {...sliderSettings}>
              {teamMembers.map((member, index) => {
                return (
                  <ProfileCard key={member.wordpress_id}>
                    <ProfileImage
                      src={member?.image || ProfileFallback}
                      alt={member.title}
                      onClick={() => {
                        handleClick(member.wordpress_id);
                      }}
                    />
                    <ProfileName>{member?.title}</ProfileName>
                    <ProfileTitle>{member?.acf?.title}</ProfileTitle>
                    <Button
                      style="minimal_plus"
                      onClick={() => {
                        handleClick(member.wordpress_id);
                      }}
                    >
                      Read Bio
                    </Button>
                  </ProfileCard>
                );
              })}
            </Slider>
          </MobileSlider>
          <TeamGrid>
            {teamMembers.map((member, index) => {
              return (
                <ProfileCard key={member.wordpress_id}>
                  <ProfileImage
                    src={member?.image || ProfileFallback}
                    alt={member.title}
                    onClick={() => {
                      handleClick(member.wordpress_id);
                    }}
                  />
                  <ProfileName>{member?.title}</ProfileName>
                  <ProfileTitle>{member?.acf?.title}</ProfileTitle>
                  <Button
                    style="minimal_plus"
                    onClick={() => {
                      handleClick(member.wordpress_id);
                    }}
                  >
                    Read Bio
                  </Button>
                </ProfileCard>
              );
            })}
          </TeamGrid>
        </CoreTeamWrapper>
      </div>
      <Bio
        isModalOpen={isModalOpen}
        currentMember={currentMember}
        onClick={() => {
          setModalOpen(false);
          setCurrentMember(null);
        }}
      />
    </>
  );
};

export default CoreTeam;
