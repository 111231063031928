import React, { useState } from "react";
import { styled } from "twin.macro";
import Button from "../PageComponents/Button";

const PressContainer = styled.section`
  // border-top: 1px solid rgba(124, 135, 149, 0.3);
  padding: 60px 48px;
  text-align: center;

  h1,
  h2,
  h3,
  h4 {
    font-weight: 600;
    font-size: 32px;
    line-height: 35px;
    letter-spacing: -0.81px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.27px;
  }

  @media (min-width: 768px) {
    padding: 90px 118px;

    h1,
    h2,
    h3,
    h4 {
      font-size: 46px;
      line-height: 56px;
      letter-spacing: -1.16px;
    }

    p {
      font-size: 19px;
      line-height: 29px;
      letter-spacing: -0.36px;
    }
  }

  @media (min-width: 1024px) {
    padding: 118px 188px;

    h1,
    h2,
    h3,
    h4 {
      font-size: 64px;
      line-height: 77px;
      letter-spacing: -1.62px;
    }

    p {
      font-size: 22px;
      line-height: 34px;
      letter-spacing: -0.41px;
    }
  }
`;

const Description = styled.div`
  margin-bottom: 40px;

  @media (min-width: 1024px) {
    margin-bottom: 60px;
  }
`;

const PressList = styled.ul`
  list-style: none;
`;

const Press = styled.li`
  margin-bottom: 20px;
  cursor: pointer;
  transition: opacity 0.25s ease;

  &:last-of-type {
    margin-bottom: 40px;
  }

  &:hover {
    opacity: 0.6;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @media (min-width: 1024px) {
    &:last-of-type {
      margin-bottom: 60px;
    }
  }
`;

const Headline = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.14px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.18px;
  }
  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.56px;
    max-width: 65ch;
    margin: 0 auto 5px;
  }
`;

const Source = styled.p`
  color: var(--color-magenta);
  font-family: attribute-mono, monospace;
  text-transform: uppercase;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.14px;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
  @media (min-width: 1024px) {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
`;

const InThePress = ({ content, press }) => {
  const [displayedPress, setDisplayedPress] = useState(press.slice(0, 5));

  const handleClick = () => {
    const startIndex = displayedPress.length;
    const nextArray = press.slice(startIndex, startIndex + 5);

    nextArray.length > 0 &&
      setDisplayedPress(prevArray => [...prevArray, ...nextArray]);
  };
  return (
    <PressContainer className="section-border">
      <Description dangerouslySetInnerHTML={{ __html: content }} />
      {press.length > 0 && (
        <PressList>
          {displayedPress.map((item, index) => (
            <Press key={index}>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <Headline>{item.title}</Headline>
                <Source>{item.source}</Source>
              </a>
            </Press>
          ))}
          {displayedPress.length < press.length && (
            <Button style="dark" onClick={handleClick}>
              View More
            </Button>
          )}
        </PressList>
      )}
    </PressContainer>
  );
};

export default InThePress;
