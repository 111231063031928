import React, { useState, useEffect } from "react";
import { styled } from "twin.macro";
import Button from "../PageComponents/Button";

const ListItem = styled.li`
  margin-bottom: 30px;
  overflow: hidden;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  &::last-of-type {
    margin-bottom: 0;
  }
`;

const Title = styled.h6`
  ${props => props.dangerouslySetInnerHTML ? '' : 'font-weight: 600;'}
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.5px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 27px;
    line-height: 32px;
    margin-bottom: 7px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 15px;
  }
`;

const Copy = styled.div`
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.12px;
  margin-bottom: 0.5rem;

  ul {
    margin-top: 10px;
    list-style: disc;
    padding-left: 28px;
  }

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 27px;
    letter-spacing: -0.16px;

    ul {
      margin-top: 20px;
    }
  }

  @media (min-width: 1024px) {
    max-width: 55ch;
  }
`;

const Extension = styled.div`
  margin-top: 20px;
  max-height: 0;
  height: 100%;
  transition: all 0.5s ease;

  &.expand {
    max-height: 200vh;
  }
`;

const ExtendedCopy = styled.div`
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.12px;
  margin-bottom: 0.5rem;

  ul {
    margin-top: 10px;
    list-style: disc;
    padding-left: 28px;
  }

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 27px;
    letter-spacing: -0.16px;

    ul {
      margin-top: 20px;
    }
  }

  @media (min-width: 1024px) {
    max-width: 55ch;
  }
`;

const ExpandListItem = ({ title, copy, title_has_html }) => {
  const [extended, setExtended] = useState(false);
  const [mainCopy, setMain] = useState(null);
  const [additionalCopy, setAdditional] = useState(null);

  useEffect(() => {
    // This checks to see if there is a "Read More" tag from Wordpress
    // If the tag is found, we will split the content in two
    // we'll use the "extended" state to toggle the Additional copy
    if (copy.includes("<!--more-->")) {
      setMain(copy.split("<!--more-->")[0]);
      setAdditional(copy.split("<!--more-->")[1]);
    } else {
      setMain(copy);
    }
    return () => {};
  }, [copy]);

  return (
    <ListItem>
      {title && !title_has_html && <Title>{title}</Title>}
      {title && title_has_html && <Title dangerouslySetInnerHTML={{ __html: title }} />}
      <Copy dangerouslySetInnerHTML={{ __html: mainCopy }} />
      {additionalCopy && (
        <>
          <Button
            style={extended ? "minimal_close" : "minimal_plus"}
            onClick={() => setExtended(prevState => !prevState)}
          >
            Details
          </Button>
          <Extension className={extended ? "expand" : ""}>
            <ExtendedCopy
              dangerouslySetInnerHTML={{ __html: additionalCopy }}
            />
          </Extension>
        </>
      )}
    </ListItem>
  );
};

export default ExpandListItem;
