import React from "react";
import { styled } from "twin.macro";

const TwoPanelsWrapper = styled.section`
  // border-top: 1px solid rgba(124,135,149,0.3);
  padding: 60px 48px;

  @media (min-width: 768px) {
    padding: 90px 118px;
  }
  @media (min-width: 1024px) {
    display: flex;
    padding: 110px 118px;
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const Panel = styled.aside`
  &.left {
    h1,
    h2 {
      font-size: 32px;
      line-height: 35px;
      letter-spacing: -1px;
      margin-bottom: 8px;
      font-weight: 600;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.27px;
      margin-bottom: 25px;

      a.btn {
        background-color: var(--color-vibrantBlue);
        color: #fff;
        font-family: attribute-mono, monospace;
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        transition: opacity 0.25s ease, filter 0.25s ease;

        &:hover {
          opacity: 0.5;
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
        }

        width: 98px;
        height: 30px;
        padding: 7.5px 20px;
        border-radius: 18px;

        font-size: 11px;
      }
    }

    .left-details {
      display: none;
    }

    @media (min-width: 768px) {
      h1,
      h2 {
        font-size: 46px;
        line-height: 56px;
        letter-spacing: -1.16px;
        margin-bottom: 5px;
      }
      p {
        font-size: 19px;
        line-height: 29px;
        letter-spacing: -0.25px;
        max-width: 45ch;
        margin-bottom: 35px;
        a.btn {
          width: 140px;
          height: 40px;
          padding: 9px 25px;
          border-radius: 24px;

          font-size: 15px;
          letter-spacing: 0.34px;
        }
      }
    }

    @media (min-width: 1024px) {
      padding-right: 25px;
      h1,
      h2 {
        font-size: 64px;
        line-height: 77pxpx;
        letter-spacing: -1.5px;
        margin-bottom: 25px;
      }
      p {
        font-size: 22px;
        line-height: 34px;
        letter-spacing: -0.41px;
        max-width: 35ch;
        margin-bottom: 45px;
        a.btn {
          width: 175px;
          height: 50px;
          padding: 11px 30px;
          border-radius: 30px;

          font-size: 19px;
          letter-spacing: 0.5px;
        }
      }

      .left-details {
        display: block;
        p {
          font-size: 17px;
          line-height: 27px;
          letter-spacing: -0.16px;
          max-width: 47.5ch;
        }
      }
    }
  }

  &.right {
    h4 {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }

    h5 {
      font-weight: 600;
      font-size: 16px;
      font-size: 18px;
      margin-bottom: 24px;
    }

    p.small {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 20px;
    }

    p,
    li {
      strong {
        font-weight: 600;
      }
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.4px;
      margin-bottom: 10px;
    }

    @media (min-width: 768px) {
      h4 {
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.15px;
        margin-bottom: 20px;
      }

      p,
      li {
        font-size: 22px;
        line-height: 34px;
        letter-spacing: -0.56px;
      }
    }

    @media (min-width: 1024px) {
      h4 {
        padding-top: 10px;
        font-size: 18px;
        line-height: 23px;
      }

      p,
      li {
        font-size: 27px;
        line-height: 42px;
        letter-spacing: -0.68px;
        margin-bottom: 15px;
      }
    }
  }

  @media (min-width: 1024px) {
    width: 50%;
  }
`;

const LeftAdditionalContent = styled.section`
  margin-top: 30px;

  p {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: -0.12px;
    margin-bottom: 10px;
  }

  p a.btn {
    display: inline-block;
    margin-top: 1rem;
  }

  @media (min-width: 768px) {
    margin-top: 40px;

    p {
      font-size: 17px;
      line-height: 26px;
      letter-spacing: -0.16px;
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

const TwoPanels = ({ list, left, right }) => {
  // I've noticed few types of right panels
  // WYSIWYG with optional extendable items, or header + content
  // For Get Involved page, we'll just pass along the HTML & style accordingly.

  // check if left column needs to be split
  const splitLeft = left.includes("<!--more-->");

  let leftContent, leftAdditionalContent;

  if (splitLeft) {
    leftContent = left.split("<!--more-->")[0];
    leftAdditionalContent = left.split("<!--more-->")[1];
  }

  return (
    <div className="section-border">
      <TwoPanelsWrapper>
        {splitLeft ? (
          <Panel className="left">
            <div dangerouslySetInnerHTML={{ __html: leftContent }} />
            <div
              dangerouslySetInnerHTML={{ __html: leftAdditionalContent }}
              className="left-details"
            ></div>
          </Panel>
        ) : (
          <Panel dangerouslySetInnerHTML={{ __html: left }} className="left" />
        )}

        {list ? (
          <Panel>{right}</Panel>
        ) : (
          <Panel
            dangerouslySetInnerHTML={{ __html: right }}
            className="right"
          />
        )}
        {splitLeft && (
          <LeftAdditionalContent
            dangerouslySetInnerHTML={{ __html: leftAdditionalContent }}
          />
        )}
      </TwoPanelsWrapper>
    </div>
  );
};

export default TwoPanels;
